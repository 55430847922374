
  import { computed, defineComponent, ref, onMounted, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import { PackageData } from '@/store/modules/Shipments/ShipmentsModule';

  export default defineComponent({
    name: 'packages-creating',
    props: {
      shipmentId: { type: String, required: true },
    },
    emit: ['list-changed'],
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const newPackagesModalRef = ref<null | HTMLElement>(null);
      const submitPullButton = ref<HTMLButtonElement | null>(null);
      const store = useStore();
      const { t, te } = useI18n();
      const attachmentField = ref<HTMLButtonElement | null>(null);
      const isButtonDisabled = ref(true);
      const packages = ref<PackageData>({
        invalidPackages: [],
        invalidPackagesCount: 0,
        sucssesPackagesCount: 0,
      });
      const packageFile = ref('');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      watch(
        () => packageFile.value,
        () => {
          if (packageFile.value) {
            console.log(packageFile.value);
            isButtonDisabled.value = false;
          }
        }
      );

      const progressValue = ref(0);

      const loading = ref(false);

      const onSubmitPull = async (values) => {
        loading.value = true;
        progressValue.value = 0;
        if (packageFile.value) {
          isButtonDisabled.value = true;
        }

        for (let i = 0; i < 50; i++) {
          await new Promise((resolve) => setTimeout(resolve, 15)); // Simulate delay
          progressValue.value = i + 1; // Update progress value
        }

        let attachmentsFormData: FormData | null = null;

        if (values.attachment) {
          attachmentsFormData = new FormData();
          attachmentsFormData.append('excel', values.attachment);
        }

        delete values.attachment;

        const attachment = await store.dispatch(Actions.IMPORT_PACKAGES, {
          data: attachmentsFormData,
          shipmentId: props.shipmentId,
        });

        progressValue.value = 50;

        if (attachment.length === 0) {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_PACKAGE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emit('list-changed');
            hideModal(newPackagesModalRef.value);
          });
          return;
        }

        packages.value = attachment;

        loading.value = false;
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_PULLED_PACKAGES'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
        isButtonDisabled.value = false;
      };

      const paymentSchema = Yup.object().shape({
        packages: Yup.array().of(
          Yup.object().shape({
            areaId: Yup.string().required(() =>
              translate('AREA_REQUIRED_FIELD')
            ),
            recipientPhoneNumber: Yup.string().required(() =>
              translate('PHONE_REQUIRED_FIELD')
            ),
          })
        ),
      });

      const submit = async (values, { resetForm }) => {
        if (!submitButtonRef.value) {
          return;
        }

        //Disable button
        submitButtonRef.value.disabled = true;
        // Activate indicator
        submitButtonRef.value.setAttribute('data-kt-indicator', 'on');

        values = {
          packages: packages.value.invalidPackages.map((pkg) => ({
            ...pkg,
            areaId: pkg.areaId || null,
            docId: String(pkg.docId),
          })),
        };

        await store.dispatch(Actions.CREATE_PACKAGES, {
          id: props.shipmentId,
          data: values,
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_PACKAGE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emit('list-changed');
            resetForm();
            hideModal(newPackagesModalRef.value);
            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
          });
        }
      };
      onMounted(async () => {
        await store.dispatch(Actions.GET_HUB_AREAS);
      });
      return {
        isButtonDisabled,
        progressValue,
        loading,
        translate,
        packageFile,
        attachmentField,
        paymentSchema,
        onSubmitPull,
        submit,
        packages,
        submitButtonRef,
        newPackagesModalRef,
        areas: computed(() => store.getters.hubAreasList),
      };
    },
  });
